<template>
  <DefaultLayout />
</template>

<script>
import DefaultLayout from '@/layouts/Default'

export default {
  name: 'Tabs',
  components: { 
    DefaultLayout
  }
}
</script>