import request from '@/utils/request'
const USER_PATH = '/user'
/* const headers = {	
	'authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJ1c2VybmFtZSI6ImFkbWluIiwic2Vzc2lvbl9pZCI6ImI4NDVmNTNkLTc3MWUtNDEzMi1hM2I5LTY4ZmRlNTQ1MjEwZCIsImV4cCI6MTYzNjQ3MTk3Niwib3JpZ19pYXQiOjE2MzYzODU1NzZ9.VlPaCHPamRp4Smsh1EBo7pvIs1d00JgcwA1KzmJGVbg'
} */

// 메뉴 목록 조회
const listMenu = (query) => {
  return request({
    url: `${USER_PATH}/board/menus/`,
    method: 'get',
    params: query
		// headers
  });
}

// 메뉴 상세 조회
const getMenu = (menuId) => {
  return request({
    url: `${USER_PATH}/board/menus/` + menuId,
    method: 'get'
  });
}

export {
	listMenu,
	getMenu
}
