<template>
  <Menu :datas="menus"></Menu>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>Tab1</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-tabs>
        <ion-router-outlet id="main"></ion-router-outlet>
        
        <ion-tab-bar slot="bottom">
          <ion-tab-button tab="tab1" href="/tabs/tab1">
            <ion-icon :icon="triangle" />
            <ion-label>Tab 1</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="tab2" href="/tabs/tab2">
            <ion-icon :icon="ellipse" />
            <ion-label>Tab 2</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="tab3" href="/tabs/tab3">
            <ion-icon :icon="square" />
            <ion-label>Tab 3</ion-label>
          </ion-tab-button>
        </ion-tab-bar>
      </ion-tabs>
    </ion-content>
  </ion-page>
</template>

<script>
import { 
  IonContent, 
  IonHeader, 
  IonRouterOutlet,
  IonButtons,
  IonMenuButton,
  IonTitle, 
  IonToolbar,
  IonPage, 
  IonTabs,
  IonTabBar, 
  IonTabButton, 
  IonIcon,
  IonLabel 
} from '@ionic/vue';
import { defineComponent, ref, onMounted } from 'vue';
import { ellipse, square, triangle } from 'ionicons/icons';
import Menu from '@/layouts/components/Menu'
import { listMenu } from "@/api/menu/menus";

export default defineComponent({
  name: 'Default',
  components: {
    IonContent, 
    IonHeader, 
    IonRouterOutlet,
    IonButtons,
    IonMenuButton,
    IonTitle, 
    IonToolbar,
    IonPage, 
    IonTabs,
    IonTabBar, 
    IonTabButton, 
    IonIcon,
    IonLabel,
    Menu
  },
  setup() {
    let menus = ref([])

    const getMenus = async () => {
      const { results } = await listMenu()
      menus.value = results
    }

    onMounted(() => {
      getMenus()
    })

    return {
      ellipse, 
      square, 
      triangle,
      menus
    }
  }
})
</script>