<template>
  <ion-menu side="start" menu-id="custom" class="my-custom-menu" content-id="main">
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>메뉴</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item v-for="(item, index) in datas" :key="index">
          <ion-icon :name="item.icon" slot="start"></ion-icon>
          <ion-label>{{ item.name }}</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<style>
.my-custom-menu {
  width: 500px;
}
</style>

<script>
import { 
  IonContent, 
  IonHeader, 
  IonItem, 
  IonList, 
  IonMenu, 
  IonTitle, 
  IonToolbar,
  menuController
} from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Menu',
  components: {
    IonContent, 
    IonHeader, 
    IonItem, 
    IonList, 
    IonMenu, 
    IonTitle, 
    IonToolbar
  },
  props: {
    datas: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    openFirst() {
      menuController.enable(true, 'first')
      menuController.open('first')
    },
    openEnd() {
      menuController.open('end')
    },
    openCustom() {
      menuController.enable(true, 'custom')
      menuController.open('custom')
    }
  }
});
</script>